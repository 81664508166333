import React, { Suspense } from "react";
import { ADMIN_LAYOUT, AUTH_LAYOUT } from "@constants/layouts";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import {
  DASHBOARD_ROUTE,
  LOGIN_ROUTE,
  WELCOME_ROUTE,
} from "./routes/routeNames";
import allRoutes from "./routes";
import { useAuth } from "@hooks/useAuth";
import AdminLayout from "@layouts/Admin";
import AuthLayout from "@layouts/Auth";
const NotFound = (props) => {
  return <div>Not Found</div>;
};

const LAYOUT_MAP = {
  [ADMIN_LAYOUT]: AdminLayout,
  [AUTH_LAYOUT]: AuthLayout,
};

const AdminRouter = () => {
  const getLayoutPathRoutes = (layout) => {
    const layoutRoutes = [];
    const layoutPaths = [];

    if (allRoutes) {
      allRoutes.filter((route) => {
        if (route.layout === layout || route.layout === undefined) {
          layoutPaths.push(route.path);
          layoutRoutes.push(route);
        }
      });
    }

    return { layoutRoutes, layoutPaths };
  };

  const FinalRoute = React.memo((props) => {
    const [auth] = useAuth();
    const { route } = props;

    const isUserAuthenticated = auth.isAuthenticated;
    //* If public route, then render the component
    if (route.publicRoute) {
      return <route.component {...props} />;
    }

    // * If authentication is pending or the session is being validated
    if (auth.authenticating || !auth.isSessionValidated) {
      return "Loading";
    }

    // * If user is not logged in and if it's not an auth route ( typically Login / signup ), then redirect to Login Screen
    if (!isUserAuthenticated && !route.authRoute) {
      return <Redirect to={LOGIN_ROUTE} />;
    }

    //* If user is logged in but landed on a authRoute ( Login ), then make them land on Home Screen
    if (isUserAuthenticated && route.authRoute) {
      return <Redirect to={DASHBOARD_ROUTE} />;
    }

    return <route.component {...props} />;
  });

  const ResolveRoutes = () => {
    return Object.keys(LAYOUT_MAP).map((layout, index) => {
      const LayoutTag = LAYOUT_MAP[layout];

      const { layoutPaths, layoutRoutes } = getLayoutPathRoutes(layout);
      const routerProps = {};

      return (
        <Route path={layoutPaths} key={`layout-map-${index}`}>
          <LayoutTag routerProps={routerProps}>
            <Switch>
              {layoutRoutes.map((route) => {
                return (
                  <Route
                    key={`react-route-${route.path}`}
                    exact={route.exact === true}
                    path={route.path}
                    render={(props) => {
                      Object.assign(routerProps, { ...props });
                      return (
                        <Suspense fallback={null}>
                          <FinalRoute route={route} {...props} />
                        </Suspense>
                      );
                    }}
                  />
                );
              })}
            </Switch>
          </LayoutTag>
        </Route>
      );
    });
  };

  return (
    <Router basename={process.env.REACT_APP_BASE_ROUTE_NAME}>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to={WELCOME_ROUTE} />;
          }}
        />
        {ResolveRoutes()}
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default AdminRouter;
