import React from "react";
// react library for routing
import { useLocation, Route } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import SIDEBAR_MENU from "@constants/sidebarMenu";
import { useSelector } from "react-redux";

function Admin(props) {
  const auth = useSelector((state) => state.auth);
  // const rolesArray = auth.currentUser.user.roles.map((u) => u.name)
  const [sidebarMenu, setSidebarMenu] = React.useState([]);

  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const location = useLocation();
  const mainContentRef = React.useRef(null);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  React.useEffect(() => {
    if (auth.isAuthenticated === true) {
      console.log(auth);
      const rolesArray = auth?.currentUser?.user?.roles?.map((u) => u.name);
      const val = SIDEBAR_MENU.filter((sidebar) => {
        return sidebar.action.some((action) => rolesArray.includes(action));
      });
      val && setSidebarMenu(val);
    }
  }, [auth.isAuthenticated, auth]);

  const getMenus = (sidebarMenu) => {
    return sidebarMenu.map((prop, key) => {
      if (prop.collapse) {
        return getMenus(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < sidebarMenu.length; i++) {
      if (
        location.pathname.indexOf(
          sidebarMenu[i].layout + sidebarMenu[i].path
        ) !== -1
      ) {
        return sidebarMenu[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };
  return (
    <>
      <Sidebar
        menu={sidebarMenu}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: "/",
          imgSrc: require("@assets/img/brand/pm_logo.png").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
        />
        <div className="mt-6 mx-5">{props.children}</div>
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
}

export default Admin;
