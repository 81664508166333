import { lazy } from "react"
import { ADMIN_LAYOUT } from "@constants/layouts"
import { USER_ROLE, JOB_PIPELINE, JOB_DETAILS } from "../routeNames"

const hrRoutes = [
  {
    path: USER_ROLE,
    component: lazy(() => import("@src/pages/hr/userRoles/List")),
    layout: ADMIN_LAYOUT,
  },
  {
    path: JOB_PIPELINE,
    component: lazy(() => import("@src/pages/hr/jobPipeline/List")),
    layout: ADMIN_LAYOUT,
  },
  {
    path: JOB_DETAILS,
    component: lazy(() => import("@src/pages/hr/jobPipeline/components/View")),
    layout: ADMIN_LAYOUT,
  },
]

export default hrRoutes
