// AUTH STATUS
export const AUTH_REQUESTED = 'AUTH_REQUESTED'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILED = 'AUTH_FAILED'

// LOGIN STATES
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'

//FORGOT STATES
export const FORGOT_PASSWORD_REQUESTED = 'FORGOT_PASSWORD_REQUESTED'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED'
export const RESET_FORGOT_PASSWORD = 'RESET_FORGOT_PASSWORD'

export const ROLE_EDIT = 'ROLE_EDIT'
