import { lazy } from "react"
import {
  DESIGNER_DASHBOARD,
  DESIGNER_DATABASE,
  DESIGNER_DETAILS,
  DESIGNER_JOB_ALLOCATION,
  DESIGNER_PROJECT_DETAILS,
  DESIGNER_PRICING
} from "../routeNames"
import { ADMIN_LAYOUT } from "constants/layouts"

const designerRoutes = [
  {
    path: DESIGNER_DASHBOARD,
    component: lazy(() => import("@src/pages/designer/dashboard/List")),
    layout: ADMIN_LAYOUT,
  },
  {
    path: DESIGNER_JOB_ALLOCATION,
    component: lazy(() => import("@src/pages/designer/job-allocation/List")),
    layout: ADMIN_LAYOUT,
  },
  {
    path: DESIGNER_DATABASE,
    component: lazy(() => import("@src/pages/designer/database/List")),
    layout: ADMIN_LAYOUT,
  },
  {
    path: DESIGNER_DETAILS,
    component: lazy(() => import("@src/pages/designer/database/Details")),
    layout: ADMIN_LAYOUT,
  },
  {
    path: DESIGNER_PROJECT_DETAILS,
    component: lazy(() =>
      import("@src/pages/designer/database/ProjectDetails")
    ),
    layout: ADMIN_LAYOUT,
  },
  {
    path: DESIGNER_PRICING,
    component: lazy(() =>
      import("@src/pages/designer/designerPricing/List")
    ),
    layout: ADMIN_LAYOUT,
  },
]

export default designerRoutes
