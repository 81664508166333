import { lazy } from "react"
import {
  PROJECT_DASHBOARD,
  PROJECT_PROJECTS,
  PROJECT_PROJECTS_DETAILS,
} from "../routeNames"
import { ADMIN_LAYOUT } from "constants/layouts"

const projectRoutes = [
  {
    path: PROJECT_DASHBOARD,
    component: lazy(() => import("@src/pages/project/dashboard/List")),
    layout: ADMIN_LAYOUT,
  },
  {
    path: PROJECT_PROJECTS,
    component: lazy(() => import("@src/pages/project/projects/List")),
    layout: ADMIN_LAYOUT,
  },
  {
    path: PROJECT_PROJECTS_DETAILS,
    component: lazy(() => import("@src/pages/project/projects/ProjectDetails")),
    layout: ADMIN_LAYOUT,
  },
]

export default projectRoutes
