import { lazy } from "react";
import { ADMIN_LAYOUT } from "@constants/layouts";
import { HOME_ROUTE } from "../routeNames";
import vendorRoutes from "./vendor.routes";
import catalogueRoutes from "./catalogue.routes";
import orderRoutes from "./order.routes";
import designerRoutes from "./designer.routes";
import customerRoutes from "./customer.routes";
import accountRoutes from "./account.route";

import projectRoutes from "./project.routes";
import hrRoutes from "./hr.routes";
import moodboardRoutes from "./moodboard.routes";
import cmsRoutes from "./cms.routes";
import tagsRoutes from "./tags.routes";
/**
 * Route config options
 * @typedef {Object} RouteConfig
 * @property {string} path
 * @property {lazy} component
 * @property {boolean} exact
 * @property {boolean} authRoute
 * @property {boolean} publicRoute
 * @property {string} layout
 *
 */

/**
 * @type {RouteConfig[]}
 */
const AdminRoutes = [
  {
    path: HOME_ROUTE,
    component: lazy(() => import("@src/pages/vendor/dashboard/List")),
    layout: ADMIN_LAYOUT,
    action: ["manageVendor", "viewVendor"],
  },
  ...vendorRoutes,
  ...catalogueRoutes,
  ...designerRoutes,
  ...customerRoutes,
  ...orderRoutes,
  ...projectRoutes,
  ...hrRoutes,
  ...moodboardRoutes,
  ...accountRoutes,
  ...cmsRoutes,
  ...tagsRoutes,
];

export default AdminRoutes;
