import React from 'react'
import iconMap from './IconMap.js'

const Icon = ({ name, size, color, ...rest }) => {
  const Icon = iconMap[name]
  return <Icon fill={color} style={{ width: size, height: size }} {...rest} />
}

Icon.defaultProps = {
  size: '5em',
  color: '#544F46'
}

export default Icon
