import {
  AUTH_FAILED,
  AUTH_REQUESTED,
  AUTH_SUCCESS,
  LOGIN_FAILED,
  LOGIN_REQUESTED,
  LOGIN_SUCCESS,
  FORGOT_PASSWORD_REQUESTED,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  RESET_FORGOT_PASSWORD
} from "../../statusTypes"
import { AuthApi } from "../../../api/auth/Auth.api"
import { AUTH_TOKEN } from "../../../constants/localKeys"

export const handleAuthSuccess = res => dispatch => {
  dispatch({ type: AUTH_SUCCESS, data: res })
}

const handleAuthFailure = error => dispatch => {
  dispatch({ type: AUTH_FAILED, error })
}

const handleLoginError = error => {
  return dispatch => {
    dispatch({ type: LOGIN_FAILED, error: { message: error } })
  }
}

export const handleLoginSuccess = res => {
  localStorage.setItem(AUTH_TOKEN, `Bearer ${res.tokens.access.token}`)
  return dispatch => {
    dispatch({ type: LOGIN_SUCCESS, data: res.designer })
  }
}


export const handleLoginAction = values => dispatch => {
  dispatch({ type: LOGIN_REQUESTED })
  AuthApi.login(values)
    .then(res => {
      dispatch(handleLoginSuccess(res))
      dispatch(handleAuthSuccess(res))
    })
    .catch(error => {
      dispatch(handleLoginError(error.error))
    })


}

export const validateAuthToken = dispatch => {
  dispatch({ type: AUTH_REQUESTED })

  AuthApi.getHealthCheck()
    .then(res => {
      dispatch(handleAuthSuccess(res))
    })
    .catch(e => {
      dispatch(handleAuthFailure(e.toString()))
    })
}

export const handleLogoutAction = dispatch => {
  dispatch({ type: AUTH_REQUESTED })
  localStorage.removeItem(AUTH_TOKEN)
  AuthApi.deleteLogout().finally(() => dispatch(handleAuthFailure('')))
}

export const handleForgotPasswordFailure = err => {
  return dispatch => {
    dispatch({ type: FORGOT_PASSWORD_FAILED, error: { message: err } })
  }
}
export const handleForgotPasswordSuccess = res => {
  return dispatch => {
    dispatch({ type: FORGOT_PASSWORD_SUCCESS, data: res })
  }
}
export const handleForgotPasswordAction = values => dispatch => {
  dispatch({ type: FORGOT_PASSWORD_REQUESTED })
  AuthApi.forgotPassword(values)
    .then(res => {
      dispatch(handleForgotPasswordSuccess((res)))
    })
    .catch(error => {
      alert(error)
      dispatch(handleForgotPasswordFailure(error))
    })
}
export const resetPasswordStatus = res => {
  return dispatch => {
    dispatch({ type: RESET_FORGOT_PASSWORD, data: res })
  }
}
