import React from "react";
import ReactDOM from "react-dom";
import ReactNotification from "react-notifications-component";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";
import "react-notifications-component/dist/theme.css";

import AppRouter from "./router";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/storeConfig/store";
import SuperTokens from "supertokens-web-js";
import Session from "supertokens-web-js/recipe/session";
import EmailPassword from "supertokens-web-js/recipe/emailpassword";
//import ThirdParty from "supertokens-web-js/recipe/thirdparty";

SuperTokens.init({
  appInfo: {
    apiDomain: `${process.env.REACT_APP_ADMIN_URL}`,
    apiBasePath: "/v1/designer",
    appName: "pixelandmortar",
    websiteBasePath: "/admin",
  },
  recipeList: [Session.init(), EmailPassword.init()],
});

ReactDOM.render(
  <Provider store={store}>
    <ReactNotification />
    <AppRouter />
  </Provider>,
  document.getElementById("root")
);
