import { store } from 'react-notifications-component';

const notify = (type, title, message) => {
  store.addNotification({
    title,
    message,
    type,
    insert: "top",
    container: "top-full",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  })
}

export default notify;
