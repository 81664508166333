import { lazy } from 'react'
import { AUTH_LAYOUT } from '@constants/layouts'
import { LOGIN_ROUTE } from '../routeNames'

/**
 * Route config options
 * @typedef {Object} RouteConfig
 * @property {string} path
 * @property {lazy} component
 * @property {boolean} exact
 * @property {boolean} authRoute
 * @property {boolean} publicRoute
 *
 */

/**
 * @type {RouteConfig[]}
 */
const AuthRoutes = [
    {
        path: LOGIN_ROUTE,
        component: lazy(() => import('@src/pages/auth/Login')),
        layout: AUTH_LAYOUT,
        authRoute: true
    }
]

export default AuthRoutes
