import { lazy } from 'react'
import { ADMIN_LAYOUT } from '@constants/layouts'
import {
  PROJECT_ACCOUNT,
  PRODUCT_ACCOUNT
} from "../routeNames"

const accountRoutes = [
  {
    path: PROJECT_ACCOUNT,
    component: lazy(() => import('@src/pages/accounts/projectAccount/List')),
    layout: ADMIN_LAYOUT
  },
  {
    path: PRODUCT_ACCOUNT,
    component: lazy(() => import('@src/pages/accounts/productAccount/List')),
    layout: ADMIN_LAYOUT
  },

]

export default accountRoutes
