import {
  Project,
  Customer,
  Designer,
  CatalogueIcon,
  Account,
  Order,
  Hr,
  Vendor,
  Content,
  Tag,
} from "@assets/IconComponent";

const SIDEBAR_MENU = [
  {
    collapse: true,
    name: "Project Management",
    icon: Project,
    state: "projectCollapse",
    action: ["manageProject", "viewProject"],
    views: [
      {
        path: "/project-dashboard",
        name: "Dashboard",
        layout: "/project",
        subMenu: true,
      },
      {
        path: "/projects/1",
        name: "Projects",
        layout: "/project",
        subMenu: true,
      },
    ],
  },

  {
    collapse: true,
    name: "Designer Management",
    icon: Designer,
    state: "designerCollapse",
    action: ["manageDesigner", "viewDesigner"],
    views: [
      {
        path: "/designer-dashboard",
        name: "Dashboard",
        layout: "/designer",
        subMenu: true,
      },
      {
        path: "/designer-job-allocation",
        name: "Job Allocation",
        layout: "/designer",
        subMenu: true,
      },
      {
        path: "/designer-database",
        name: "Designer Database",
        layout: "/designer",
        subMenu: true,
      },
      {
        path: "/designer-training-module",
        name: "Training Module",
        layout: "/designer",
        subMenu: true,
      },
      {
        path: "/designer-pricing",
        name: "Designer Pricing",
        layout: "/designer",
        subMenu: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Vendor Management",
    icon: Vendor,
    state: "vendorCollapse",
    action: ["manageVendor", "viewVendor"],
    views: [
      {
        path: "/vendor-dashboard",
        name: "Dashboard",
        layout: "/vendor",
        subMenu: true,
      },
      {
        path: "/vendor-database",
        name: "Vendor Database",
        layout: "/vendor",
        subMenu: true,
      },
      {
        path: "/vendor-payments",
        name: "Payments",
        layout: "/vendor",
        subMenu: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Catalogue Management",
    icon: CatalogueIcon,
    state: "catalogueCollapse",
    action: ["manageCatalogue", "viewCatalogue"],
    views: [
      {
        path: "/catalogue-dashboard",
        name: "Dashboard",
        layout: "/catalogue",
        subMenu: true,
      },
      {
        path: "/product-catalogue",
        name: "Product Catalogue",
        layout: "/catalogue",
        subMenu: true,
      },
      {
        path: "/product-verification",
        name: "Product Verification",
        layout: "/catalogue",
        subMenu: true,
      },
      {
        path: "/product-tagging",
        name: "Product Tagging",
        layout: "/catalogue",
        subMenu: true,
      },
      {
        path: "/3d-model",
        name: "3D Model",
        layout: "/catalogue",
        subMenu: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Order Management",
    icon: Order,
    state: "orderCollapse",
    action: ["manageOrder", "viewOrder"],
    views: [
      {
        path: "/order-dashboard",
        name: "Dashboard",
        layout: "/order",
        subMenu: true,
      },
      {
        path: "/order-database/1",
        name: "Orders",
        layout: "/order",
        subMenu: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Account Management",
    icon: Account,
    state: "accountCollapse",
    action: ["manageAccount", "viewAccount"],
    views: [
      {
        path: "/project-accounts",
        name: "Project Accounts",
        layout: "/accounts",
        subMenu: true,
      },
      {
        path: "/product-accounts/1",
        name: "Product Accounts",
        layout: "/accounts",
        subMenu: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Customer Management",
    icon: Customer,
    state: "customerCollapse",
    action: ["manageCustomer", "viewCustomer"],
    views: [
      {
        path: "/customer-dashboard",
        name: "Dashboard",
        layout: "/customer",
        subMenu: true,
      },
      {
        path: "/review-management",
        name: "Review Management",
        layout: "/customer",
        subMenu: true,
      },
      {
        path: "/query-management",
        name: "Query Management",
        layout: "/customer",
        subMenu: true,
      },
    ],
  },
  {
    collapse: true,
    name: "HR Management",
    icon: Hr,
    state: "hrCollapse",
    action: ["manageHR", "viewHR"],
    views: [
      {
        path: "/job-pipeline",
        name: "Job Pipeline",
        layout: "/hr",
        subMenu: true,
      },
      {
        path: "/user-roles",
        name: "User Roles",
        layout: "/hr",
        subMenu: true,
      },
    ],
  },
  {
    collapse: true,
    name: "MoodBoard",
    icon: Vendor,
    state: "moodBoardCollapse",
    action: ["manageMoodboard", "viewMoodboard"],
    views: [
      {
        path: "/moodboard-database",
        name: "Database",
        layout: "/moodboard",
        subMenu: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Content Management",
    icon: Content,
    state: "contentCollapse",
    action: ["manageProject", "viewProject"],
    views: [
      {
        path: "/faq-management",
        name: "FAQ Management",
        layout: "/content",
        subMenu: true,
      },
      {
        path: "/shop-management",
        name: "Shop Management",
        layout: "/content",
        subMenu: true,
      },
      {
        path: "/featured-moodboard-management",
        name: "Moodboard Management",
        layout: "/content",
        subMenu: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Tags",
    icon: Tag,
    state: "tagsCollapse",
    action: ["manageProject", "viewProject"],
    views: [
      {
        path: "/tags-database",
        name: "Tags Database",
        layout: "/tags",
        subMenu: true,
      },
    ],
  },
];

export default SIDEBAR_MENU;
