import { AUTH_SUCCESS } from "redux/statusTypes";
import mxdApiInstance from "..";
import { AUTH_TOKEN } from "../../constants/localKeys";
import { ADMIN_LOGIN_IN_URL, HEALTH_CHECK_URL1 } from "../endpoints";

const API_DELAY = 2000;

const mockResponse = {
  login: { name: "John Doe" },
  forgotPassword: { message: "success" },
};

export const AuthApi = {
  getHealthCheck: async () => {
    const authToken = localStorage.getItem(AUTH_TOKEN);
    if (!authToken) throw new Error("Un Authorized");
    try {
      const response = await mxdApiInstance.get(HEALTH_CHECK_URL1);
      console.log("healthcheck response", response);
      return response;
    } catch (err) {
      //return mockResponse.login
      console.log(err);
    }
  },
  deleteLogout: async () => {
    const response = await new Promise((res, rej) =>
      setTimeout(res, API_DELAY)
    );
    return response;
  },
  login: async (data) => {
    const response = await mxdApiInstance.post(ADMIN_LOGIN_IN_URL, data);
    return response;
  },
  forgotPassword: async (values) => {
    const response = await new Promise((res, rej) =>
      setTimeout(() => res(mockResponse.forgotPassword), API_DELAY)
    );
    return response;
  },
};
