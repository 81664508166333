import {
  AUTH_FAILED,
  AUTH_REQUESTED,
  AUTH_SUCCESS,
  LOGIN_FAILED,
  LOGIN_REQUESTED,
  LOGIN_SUCCESS,
  FORGOT_PASSWORD_REQUESTED,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  RESET_FORGOT_PASSWORD,
  ROLE_EDIT,
} from "../../statusTypes"
import initialState from "./initialState"
import notify from "../../../utility/notification"

const authReducer = (state = initialState, action) => {
  const nextState = Object.assign({}, { ...state })
  switch (action.type) {
    case AUTH_REQUESTED:
      nextState.status = AUTH_REQUESTED
      return nextState
    case AUTH_SUCCESS:
      nextState.currentSessionValidated = true
      nextState.isAuthenticated = true
      nextState.currentUser = action.data
      nextState.login = Object.assign({}, state.login)
      nextState.status = AUTH_SUCCESS
      return nextState

    case AUTH_FAILED:
      nextState.currentSessionValidated = true
      nextState.isAuthenticated = false
      nextState.currentUser = {}
      nextState.status = AUTH_FAILED
      nextState.login = Object.assign({}, state.login)
      return nextState

    // LOGIN REDUCER
    case LOGIN_REQUESTED:
      nextState.login.status = LOGIN_REQUESTED
      return nextState
    case LOGIN_SUCCESS:
      nextState.login = {
        ...nextState.login,
        status: LOGIN_SUCCESS,
        data: action.data,
        error: null,
      }
      nextState.auth = AUTH_SUCCESS
      nextState.test = "test"
      notify("success", "Success", "Login success")
      return nextState
    case LOGIN_FAILED:
      nextState.login = {
        ...nextState.login,
        status: LOGIN_FAILED,
        data: {},
        error: action.error,
      }
      notify("danger", "Login Failed", "Invalid credentials")
      return nextState

    //FORGOT PASSWORD REDUCER
    case FORGOT_PASSWORD_REQUESTED:
      nextState.password.status = FORGOT_PASSWORD_REQUESTED
      return nextState
    case FORGOT_PASSWORD_SUCCESS:
      nextState.password.status = FORGOT_PASSWORD_SUCCESS
      return nextState
    case FORGOT_PASSWORD_FAILED:
      nextState.password.status = FORGOT_PASSWORD_FAILED
      alert(JSON.stringify(action))
      // showErrorToast({
      //   title: 'Error',
      //   description: "action"
      // })
      return nextState
    case RESET_FORGOT_PASSWORD:
      nextState.password.status = ""
      return nextState
    case ROLE_EDIT:
      nextState.currentUser.user = action.data
      return nextState
    default:
      return nextState
  }
}

export default authReducer
