import { AlarmAddSharp } from "@mui/icons-material";

// BASE URL
export const DOMAIN = process.env.REACT_APP_BASE_API_URL;
export const API_BASE_URL = `${DOMAIN}/v1`;
export const CMS = `${process.env.REACT_APP_CMS_URL}/api/v1`;

// AUTH ENDPOINTS
export const GET_HEARTBEAT = `${DOMAIN}/unicorns`;
export const ADMIN_LOGIN_IN_URL = `${API_BASE_URL}/auth/login`;
export const HEALTH_CHECK_URL1 = `${API_BASE_URL}/auth/health-check`;

//CMS ENDPOINTS
export const GET_CATEGORIES = `${CMS}/user/queryheaders`;
export const GET_SUB_CATEGORIES = `${CMS}/user/queryheader/:id/subheader`;
export const ADD_CATEGORIES = `${CMS}/admin/faqheader`;
export const ADD_SUB_CATEGORIES = `${CMS}/admin/faqsubheader`;
export const GET_ALL_DATA = `${CMS}/user/allfaq`;
export const ADD_QUERRY = `${CMS}/admin/queries`;
export const DELETE_QUESTION = `${CMS}/admin/question_id/:id/queries`;
export const DELETE_CATEGORY = `${CMS}/admin/faqheader/:id/faqheader`;
export const DELETE_SUB_CATEGORY = `${CMS}/admin/faqsubheader/:id/faqsubheader`;
export const EDIT_CATEGORY = `${CMS}/admin/faqheader`;
export const EDIT_SUB_CATEGORY = `${CMS}/admin/faqsubheader`;
export const EDIT_QUERRY = `${CMS}/admin/queries`;
export const ADD_GENRICBANNER = `${CMS}/admin/genricbanner`;
export const EDIT_GENRICBANNER = `${CMS}/admin/genricbanner`;
export const GET_GENRICBANNER = `${CMS}/user/genricbanner`;
export const ADD_ROOMBANNER = `${CMS}/admin/banner`;
export const EDIT_ROOMBANNER = `${CMS}/admin/banner`;
export const GET_ROOMBANNER = `${CMS}/user/banner`;
export const UPLOAD = `${CMS}/upload`;
export const GET_FEATURED_MOODBOARDS = `${CMS}/user/explore/featured`;
export const ADD_FEATURED_MOODBOARDS = `${CMS}/admin/explore/featured`;
export const UPDATE_FEATURED_MOODBOARDS = (id) =>
  `${CMS}/admin/explore/featured/${id}`;

//TAGS ENDPOINTS
export const GET_ADMIN_TAGS = `${API_BASE_URL}/tags/hierarchy`;
export const GET_TAGS = `${API_BASE_URL}/tags`;
export const UPDATE_TAGS = (id) => `${API_BASE_URL}/tags/${id}`;
export const TAGS = `${API_BASE_URL}/tags`;
export const UPLOAD_TAG_IMAGE = `${API_BASE_URL}/file`;

//CATALOGUE ENDPOITS
export const GET_CATALOGUE_PRODUCTS = `${API_BASE_URL}/catalogue/products`;
export const GET_CATALOGUE_PRODUCT = `${API_BASE_URL}/catalogue/product`;
export const GET_3D_CATALOGUE_PRODUCTS = `${API_BASE_URL}/catalogue/products/3d-catalogue`;
export const GET_CATALOGUE_VENDORS = `${API_BASE_URL}/catalogue/vendors`;
export const POST_CATALOGUE_PRODUCT_TAG = `${API_BASE_URL}/catalogue/products/tag`;
export const GET_CATALOGUE_DASHBOARD = `${API_BASE_URL}/catalogue`;
export const GET_MULTIPART_UPLOAD_ID = `${API_BASE_URL}/catalogue/get-upload-id`;
export const GET_UPLOAD_PART = `${API_BASE_URL}/catalogue/get-upload-part`;
export const COMPLETE_MULTIPART_UPLOAD = `${API_BASE_URL}/catalogue/complete-multipart-upload`;

export const POST_3D_FILES = (id) =>
  `${API_BASE_URL}/catalogue/products/${id}/3d-model`;
export const GET_CATALOGUE_PRODUCTS_BY_SKU = `${API_BASE_URL}/catalogue/products/SKU`;
export const GET_FILE = (key) => `${API_BASE_URL}/file/${key}`;
export const CATALOGUE_PRODUCT = (id) =>
  `${API_BASE_URL}/catalogue/product/${id}`;
export const DOWNLOAD_CATALOGUE = `${API_BASE_URL}/catalogue/download`;
export const UPLOAD_FILE = `${API_BASE_URL}/file`;
export const BULK_UPLOAD_3D_MODEL = `${API_BASE_URL}/catalogue/products/3d-models/bulk-upload`;
export const SYNC_PRODUCT_PRICES = (id) =>
  `${API_BASE_URL}/catalogue/products/${id}/sync-prices`;
export const GET_3D_TAG_PRODUCTS = `${API_BASE_URL}/catalogue/tag-3d-products`;

//VENDOR ENDPOINTS
export const VENDOR_DASHBOARD_URL = `${API_BASE_URL}/vendors/vendor-dashboard`;
export const VENDOR_NAMES_URL = `${API_BASE_URL}/vendors/vendor-name`;
export const VENDOR_VIEW_URL = (id) => `${API_BASE_URL}/vendors/${id}`;
export const VENDOR_DETAILS_URL = (id) =>
  `${API_BASE_URL}/vendors/${id}/ratings`;
export const CREATE_VENDOR_URL = `${API_BASE_URL}/vendors/`;
export const VENDOR_DATABASE_URL = `${API_BASE_URL}/vendors`;
export const VENDOR_IMPORT_URL = `${API_BASE_URL}/vendors/import-file`;
export const VENDOR_IMPORTID_URL = `${API_BASE_URL}/vendors/imports/id`;
export const VENDOR_CANCEL_IMPORT_URL = (id) =>
  `${API_BASE_URL}/vendors/imports/${id}`;

export const VENDOR_FILTER_URL = `${API_BASE_URL}/vendors/filter-vendor`;
export const VENDOR_IMPORTDATA_URL = `${API_BASE_URL}/vendors/import-items`;
export const VENDOR_EDIT_URL = (id) => `${API_BASE_URL}/vendors/${id}`;
export const VENDOR_BULKIMPORT_URL = `${API_BASE_URL}/vendors/bulk-import`;
export const FILE_URL = `${API_BASE_URL}/vendors/files`;
export const GET_FILE_URL = (key) => `${API_BASE_URL}/vendors/files/${key}`;
export const DELETE_VENDOR = (id) => `${API_BASE_URL}/vendors/${id}`;
export const VERIFY_VENDOR_URL = `${API_BASE_URL}/vendors/verify-vendor`;

//ORDER ENDPOINTS
export const ORDER_DETAILS_URL = `${API_BASE_URL}/orders`;
export const GET_VENDOR_NAME = `${API_BASE_URL}/orders/vendorname`;
export const ALLORDER_DETAILS_URL = `${API_BASE_URL}/orders/all-orders`;
export const ORDER_DASHBOARD_URL = `${API_BASE_URL}/orders/order-dashboard`;
export const ORDER_ALLVENDORS_URL = `${API_BASE_URL}/orders/vendors`;
export const TODAY_ORDERS_URL = `${API_BASE_URL}/orders/today-all-orders`;
export const GET_ORDERSTATUS_URL = `${API_BASE_URL}/orders/order-status`;
export const BILL_STATUS_UPDATE = `${API_BASE_URL}/orders/update-bill-status`;
export const PRODUCT_ACCOUNTS = `${API_BASE_URL}/orders/product-accounts`;
export const ONGOING_ORDERS = `${API_BASE_URL}/orders/on-going`;
export const COMPLETED_ORDERS = `${API_BASE_URL}/orders/completed`;
export const UNACCEPTED_ORDERS = `${API_BASE_URL}/orders/unaccepted`;

//ORDERITEMS ENDPOINTS
export const GET_UNACCEPTEDORDERS_URL = `${API_BASE_URL}/orderitems/unaccepted-orders`;
export const GET_ONGOINGORDERS_URL = `${API_BASE_URL}/orderitems/ongoing-orders`;
export const GET_COMPLETEDORDERS_URL = `${API_BASE_URL}/orderitems/completed-orders`;
export const GET_ORDERITEM_URL = (orderId) =>
  `${API_BASE_URL}/orderitems/${orderId}`;
export const UPDATE_ORDERITEM_URL = (orderId) =>
  `${API_BASE_URL}/orderitems/${orderId}`;

//NOTIFICATION URL
export const GET_NOTIFICATION_URL = `${API_BASE_URL}/notifications`;
export const GET_NOTIFICATION_APPROVAL_URL = `${API_BASE_URL}/notifications/approval`;
export const UPDATE_NOTIFICATION_URL = (id) =>
  `${API_BASE_URL}/notifications/${id}`;

//REQUEST URL
export const GET_REQUEST_URL = `${API_BASE_URL}/request`;
export const GET_REQUEST_BY_VENDORID = `${API_BASE_URL}/request/vendorId`;
export const UPDATE_REQUEST_URL = (id) => `${API_BASE_URL}/request/${id}`;
export const GET_REQUEST_BY_DESIGNERID = `${API_BASE_URL}/request/designerId`;

//DESIGNER ENDPOINTS
export const DESIGNER_PRICING = `${API_BASE_URL}/designer-pricing`;
export const DESIGNER_PRICING_UPDATE = `${API_BASE_URL}/designer-pricing/synchronise/designer-levels`;
export const GET_ALL_DESIGNERS = `${API_BASE_URL}/designer/designer-data`;
export const GET_DESIGNER_LIST = `${API_BASE_URL}/designer/designer-list`;
export const GET_DESIGNER_LEADERBOARD = `${API_BASE_URL}/designer/leader-board`;
export const GET_DESIGNER_DETAILS = (id) =>
  `${API_BASE_URL}/designer/${id}/details`;
export const GET_UNASSIGNED_PROJECTS = `${API_BASE_URL}/designer/unassigned-projects`;
export const GET_DESIGNER_PROJECTS = (id) =>
  `${API_BASE_URL}/designer/${id}/projects`;
export const GET_PROJECT_DETAILS = (id) =>
  `${API_BASE_URL}/designer/${id}/projectDetails`;
export const UPDATE_ASSIGNED_DESIGNERS = (id) =>
  `${API_BASE_URL}/designer/${id}/assign-designers`;
export const UPDATE_ASSIGNED_STATUS = (id) =>
  `${API_BASE_URL}/designer/${id}/assign-status`;
export const CREATE_DESIGNER_URL = `${API_BASE_URL}/designer/`;
export const DESIGNER_FILE_URL = `${API_BASE_URL}/designer/files`;
export const GET_DESIGNER_FILE_URL = (key) =>
  `${API_BASE_URL}/designer/files/${key}`;
export const COMMON_STYLES = `${API_BASE_URL}/designer/common-styles`;
export const UPDATE_DESIGNER_DETAILS = (designerId) =>
  `${API_BASE_URL}/designer/update/${designerId}`;
export const GET_NEXT_PREV_PROJECTS = (designerId, projectId) =>
  `${API_BASE_URL}/designer/next-prev-projects/${designerId}/${projectId}`;
export const UPDATE_MOODBOARD_ADMIN_RATING = (moodboardId) =>
  `${API_BASE_URL}/designer/admin-rating/${moodboardId}`;
export const PROJECT_SETTING = `${API_BASE_URL}/designer/project-setting`;
export const DESIGNER_IMPORT_URL = `${API_BASE_URL}/designer/import-file`;
export const DESIGNER_IMPORTID_URL = `${API_BASE_URL}/designer/imports/id`;
export const DESIGNER_IMPORTDATA_URL = `${API_BASE_URL}/designer/import-items`;
export const DESIGNER_BULKIMPORT_URL = `${API_BASE_URL}/designer/bulk-import`;
export const DESIGNER_GET_FILE_URL = (key) =>
  `${API_BASE_URL}/designer/files/${key}`;

//PROJECTS ENDPOINTS
export const PROJECTS_GET_UNASSIGNED = `${API_BASE_URL}/projects/unassigned`;
export const PROJECTS_GET_ONGOING = `${API_BASE_URL}/projects/ongoing`;
export const PROJECTS_GET_COMPLETED = `${API_BASE_URL}/projects/completed`;
export const PROJECTS_GET_PROJECT_DETAILS = (projectId) =>
  `${API_BASE_URL}/projects/${projectId}/projectDetails`;
export const PROJECTS_DASHBOARD = `${API_BASE_URL}/projects/dashboard`;
export const PROJECTS_UPDATE_MOODBOARD_ADMIN_RATING = (moodboardId) =>
  `${API_BASE_URL}/projects/admin-rating/${moodboardId}`;
export const UPDATE_REVISED_MOODBOARD = (projectId) =>
  `${API_BASE_URL}/projects/revised-moodboard-admin-rating/${projectId}`;

export const LIST_PROJECT_ACCOUNTS = `${API_BASE_URL}/projects/project-accounts`;
export const PROJECT_INITIATE_PAYMENT = (projectId) =>
  `${API_BASE_URL}/projects/project-accounts/initiate-payment/${projectId}`;
export const PROJECT_BILL_STATUS = (projectId) =>
  `${API_BASE_URL}/projects/project-accounts/bill-status/${projectId}`;
export const PROJECT_ORDER_DETAILS = (projectId) =>
  `${API_BASE_URL}/projects/project-accounts/order-details/${projectId}`;

// HR ENDPOINT

export const GET_USER_URL = `${API_BASE_URL}/users`;
export const USER_URL = (userId) => `${API_BASE_URL}/users/${userId}`;
export const POST_JOB = `${API_BASE_URL}/jobs`;
export const JOB_URL = (jobId) => `${API_BASE_URL}/jobs/${jobId}`;

//MOODBOARD ENDPOINTS

export const CREATE_MOODBOARD_URL = `${API_BASE_URL}/moodboardTemplates`;
export const MOODBOARD_DATABASE_URL = `${API_BASE_URL}/moodboardTemplates`;
export const MOODBOARD_DELETE_URL = (id) =>
  `${API_BASE_URL}/moodboardTemplates/${id}`;
export const IMAGE_URL = `${API_BASE_URL}/moodboardTemplates/file`;
export const PREVIEW_IMAGE_URLS = `${API_BASE_URL}/moodboardTemplates/moodboard/images`;
export const MOODBOARD_UPDATE_URL = (id) =>
  `${API_BASE_URL}/moodboardTemplates/${id}`;
export const GET_MOODBOARD_URL = (id) =>
  `${API_BASE_URL}/moodboardTemplates/${id}`;

//CUSTOMER MANAGEMENT
export const GET_PROJECT_REVIEW = `${API_BASE_URL}/customer-management/review-projects`;
export const GET_DESIGNER_RATING = `${API_BASE_URL}/customer-management/ratings`;
export const GET_PROJECT = `${API_BASE_URL}/customer-management/project`;
export const GET_DASHBOARD_DATA = `${API_BASE_URL}/customer-management/dashboard`;
export const GET_TICKETS = `${API_BASE_URL}/customer-management/tickets`;

export const GLOBAL_SEARCH = `${API_BASE_URL}/search`;
