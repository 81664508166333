import { lazy } from "react";
import {
  FAQ_MANAGEMENT,
  SHOP_MANAGEMENT,
  MOODBOARD_MANAGEMENT,
} from "../routeNames";
import { ADMIN_LAYOUT } from "@constants/layouts";

const cmsRoutes = [
  {
    path: FAQ_MANAGEMENT,
    component: lazy(() => import("@src/pages/cms/faq/Faq")),
    layout: ADMIN_LAYOUT,
    action: ["viewVendor", "manageVendor"],
  },
  {
    path: SHOP_MANAGEMENT,
    component: lazy(() => import("@src/pages/cms/shop/shopCms")),
    layout: ADMIN_LAYOUT,
    action: ["viewVendor", "manageVendor"],
  },

  {
    path: MOODBOARD_MANAGEMENT,
    component: lazy(() => import("@src/pages/cms/moodboard/moodboardCms")),
    layout: ADMIN_LAYOUT,
    action: ["viewVendor", "manageVendor"],
  },
];

export default cmsRoutes;
