import { stringify } from "querystring";

const DEFAULT_OPTIONS = {
  params: {},
  pathParams: {},
  body: {}
}

export const handleParameter = (url, data = DEFAULT_OPTIONS, request) => {
  const { params, pathParams, body } = data;
  if (params && Object.keys(params).length) {
    url += `?${stringify(params)}`;
  }
  if (pathParams && Object.keys(pathParams).length) {
    Object.keys(pathParams).forEach(k => {
      url = url.replace(`:${k}`, `${pathParams[k]}`)
    });
  }
  request.url = url
  if (body && Object.keys(body).length) {
    request.data = body
  }
  return request;
}
