import { lazy } from "react"
import {
  MOODBOARD_DATABASE,
} from "../routeNames"
import { ADMIN_LAYOUT } from "@constants/layouts"

const moodboardRoutes = [
  {
    path: MOODBOARD_DATABASE,
    component: lazy(() => import("@src/pages/moodboard/List")),
    layout: ADMIN_LAYOUT,
    action: ["viewMoodboard", "manageMoodboard"]
  },
  
]

export default moodboardRoutes

