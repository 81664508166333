import { lazy } from 'react'
import {ORDER_DASHBOARD,ORDER_DATABASE} from "../routeNames"
import { ADMIN_LAYOUT } from '@constants/layouts'

const orderRoutes = [

  {
    path: ORDER_DASHBOARD,
    component: lazy(() => import('@src/pages/order/dashboard/List')),
    layout: ADMIN_LAYOUT
  },
  {
    path: ORDER_DATABASE,
    component: lazy(() => import('@src/pages/order/database/List')),
    layout: ADMIN_LAYOUT
  },
  
  
]

export default orderRoutes
