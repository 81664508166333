export const HOME_ROUTE = "/vendor/vendor-dashboard";
export const LOGIN_ROUTE = "/login";
export const WELCOME_ROUTE = "/vendor/vendor-dashboard";
export const DASHBOARD_ROUTE = "/vendor/vendor-dashboard";

//tags routes
export const TAGS_DATABASE = "/tags/tags-database";
//cms routes
export const FAQ_MANAGEMENT = "/content/faq-management";
export const SHOP_MANAGEMENT = "/content/shop-management";
export const MOODBOARD_MANAGEMENT = "/content/featured-moodboard-management";
//vendor routes

export const VENDOR_DASHBOARD = "/vendor/vendor-dashboard";
export const VENDOR_DATABASE = "/vendor/vendor-database";
export const VENDOR_PAYMENTS = "/vendor/vendor-payments";

export const VENDOR_NOTIFICATION = "/vendor/vendor-notification";
export const VENDOR_DETAILS = "/vendor/:vendorId/details";
export const EDIT_VIEW = "/vendor/:vendorId/edit";

//catalogue routes
export const CATALOGUE_DASHBOARD = "/catalogue/catalogue-dashboard";
export const CATALOGUE_PRODUCT_CATALOGUE = "/catalogue/product-catalogue";
export const CATALOGUE_VERIFICATION_PRODUCT_DETAIL_EDIT =
  "/catalogue/product-verification/:id/edit";
export const CATALOGUE_VERIFICATION_PRODUCT_DETAIL_VIEW =
  "/catalogue/product-verification/:id";
export const CATALOGUE_PRODUCT_DETAIL_EDIT =
  "/catalogue/product-catalogue/:id/edit";
export const CATALOGUE_PRODUCT_DETAIL_VIEW = "/catalogue/product-catalogue/:id";
export const CATALOGUE_PRODUCT_VERIFICATION = "/catalogue/product-verification";
export const CATALOGUE_PRODUCT_TAGGING = "/catalogue/product-tagging";
export const CATALOGUE_PRODUCT_3D_MODEL = "/catalogue/3d-model";
export const CATALOGUE_PAYMENTS = "/catalogue/catalogue-payments";

//order routes
export const ORDER_DASHBOARD = "/order/order-dashboard";
export const ORDER_DATABASE = "/order/order-database/:tabId";

//designer routes
export const DESIGNER_DASHBOARD = "/designer/designer-dashboard";
export const DESIGNER_JOB_ALLOCATION = "/designer/designer-job-allocation";
export const DESIGNER_DATABASE = "/designer/designer-database";
export const DESIGNER_DETAILS = "/designer/:designerId/details";
export const DESIGNER_PROJECT_DETAILS =
  "/designer/:designerId/project/:projectId/details";
export const DESIGNER_PRICING = "/designer/designer-pricing";

//customer routes
export const CUSTOMER_DASHBOARD = "/customer/customer-dashboard";
export const CUSTOMER_REVIEW = "/customer/review-management";
export const PROJECT_DETAIL =
  "/customer/review-management/project/:projectId/details";
export const QUERY_MANAGEMENT = "/customer/query-management";
export const QUERY_PROJECT_DETAIL =
  "/customer/query-management/project/:projectId/details";
//project routes
export const PROJECT_DASHBOARD = "/project/project-dashboard";
export const PROJECT_PROJECTS = "/project/projects/:tabId";
export const PROJECT_PROJECTS_DETAILS = "/project/:projectId/details";

// HR ROUTES
export const USER_ROLE = "/hr/user-roles";
export const JOB_PIPELINE = "/hr/job-pipeline";
export const JOB_DETAILS = "/hr/job/:jobId/details";

//MOODBOARD ROUTES
export const MOODBOARD_DATABASE = "/moodboard/moodboard-database";

//account
export const PROJECT_ACCOUNT = "/accounts/project-accounts";
export const PRODUCT_ACCOUNT = "/accounts/product-accounts/:tabId";
