import { ReactComponent as Account } from "./icons/account.svg";
import { ReactComponent as Apps } from "./icons/apps.svg";
import { ReactComponent as ArrowDown } from "./icons/arrow_down.svg";
import { ReactComponent as ArrowUp } from "./icons/arrow_up.svg";
import { ReactComponent as Bell } from "./icons/bell.svg";
import { ReactComponent as Cart } from "./icons/cart.svg";
import { ReactComponent as CartFilled } from "./icons/cart_filled.svg";
import { ReactComponent as Catalogue } from "./icons/catalogue.svg";
import { ReactComponent as CatalogueIcon } from "./icons/catalogue_icon.svg";
import { ReactComponent as CharmCircleTick } from "./icons/charm_circle-tick.svg";
import { ReactComponent as Chat } from "./icons/chat.svg";
import { ReactComponent as CircleTick } from "./icons/circle_tick.svg";
import { ReactComponent as Close } from "./icons/close.svg";
import { ReactComponent as CloseWhite } from "./icons/close_white.svg";
import { ReactComponent as Completed } from "./icons/completed.svg";
import { ReactComponent as Correct } from "./icons/correct.svg";
import { ReactComponent as Customer } from "./icons/customer.svg";
import { ReactComponent as DeleteWhite } from "./icons/delete_white.svg";
import { ReactComponent as Designer } from "./icons/designer.svg";
import { ReactComponent as Download } from "./icons/download.svg";
import { ReactComponent as DownloadArrow } from "./icons/download_arrow.svg";
import { ReactComponent as DownloadWhite } from "./icons/download_white.svg";
import { ReactComponent as Edit } from "./icons/edit.svg";
import { ReactComponent as Graph } from "./icons/graph.svg";
import { ReactComponent as GreenTick } from "./icons/green_tick.svg";
import { ReactComponent as Grid } from "./icons/grid.svg";
import { ReactComponent as Group } from "./icons/Group.svg";
import { ReactComponent as HeartFilled } from "./icons/heart_filled.svg";
import { ReactComponent as HeartGrayed } from "./icons/heart_grayed.svg";
import { ReactComponent as Hidden } from "./icons/hidden.svg";
import { ReactComponent as Home } from "./icons/home.svg";
import { ReactComponent as Hr } from "./icons/hr.svg";
import { ReactComponent as LeftArrowFilled } from "./icons/left_arrow_filled.svg";
import { ReactComponent as LeftArrowGrayed } from "./icons/left_arrow_grayed.svg";
import { ReactComponent as List } from "./icons/list.svg";
import { ReactComponent as LoginBackground } from "./icons/login_background.svg";
import { ReactComponent as ModelD } from "./icons/model_3d.svg";
import { ReactComponent as Order } from "./icons/order.svg";
import { ReactComponent as OrderCart } from "./icons/order_cart.svg";
import { ReactComponent as OrderCartFilled } from "./icons/order_cart_filled.svg";
import { ReactComponent as OrderExclamatory } from "./icons/order_exclamatory.svg";
import { ReactComponent as OrderTime } from "./icons/order_time.svg";
import { ReactComponent as Picture } from "./icons/picture.svg";
import { ReactComponent as ProductDModel } from "./icons/product_3d_model.svg";
import { ReactComponent as ProductCart } from "./icons/product_cart.svg";
import { ReactComponent as ProductMoodboard } from "./icons/product_moodboard.svg";
import { ReactComponent as ProductVerified } from "./icons/product_verified.svg";
import { ReactComponent as Project } from "./icons/project.svg";
import { ReactComponent as ProjectIcon } from "./icons/project_icon.svg";
import { ReactComponent as ProjectTimerIcon } from "./icons/Project_timer_icon.svg";
import { ReactComponent as RightArrowFilled } from "./icons/right_arrow_filled.svg";
import { ReactComponent as RightArrowGrayed } from "./icons/right_arrow_grayed.svg";
import { ReactComponent as Rupee } from "./icons/rupee.svg";
import { ReactComponent as Search } from "./icons/search.svg";
import { ReactComponent as SidebarBg } from "./icons/sidebar_bg.svg";
import { ReactComponent as StarEmpty } from "./icons/star_empty.svg";
import { ReactComponent as StarFilled } from "./icons/star_filled.svg";
import { ReactComponent as Ticked } from "./icons/ticked.svg";
import { ReactComponent as Time } from "./icons/time.svg";
import { ReactComponent as UnassignedProjectsIcon } from "./icons/unassigned_projects_icon.svg";
import { ReactComponent as Vector } from "./icons/vector.svg";
import { ReactComponent as Vendor } from "./icons/vendor.svg";
import { ReactComponent as View } from "./icons/view.svg";
import { ReactComponent as Content } from "./icons/content.svg";
import { ReactComponent as Tag } from "./icons/content.svg";

export default {
  account: Account,
  apps: Apps,
  arrow_down: ArrowDown,
  arrow_up: ArrowUp,
  bell: Bell,
  cart: Cart,
  cart_filled: CartFilled,
  catalogue: Catalogue,
  catalogue_icon: CatalogueIcon,
  "charm_circle-tick": CharmCircleTick,
  chat: Chat,
  circle_tick: CircleTick,
  close: Close,
  close_white: CloseWhite,
  completed: Completed,
  correct: Correct,
  customer: Customer,
  delete_white: DeleteWhite,
  designer: Designer,
  download: Download,
  download_arrow: DownloadArrow,
  download_white: DownloadWhite,
  edit: Edit,
  graph: Graph,
  green_tick: GreenTick,
  grid: Grid,
  Group: Group,
  heart_filled: HeartFilled,
  heart_grayed: HeartGrayed,
  hidden: Hidden,
  home: Home,
  hr: Hr,
  left_arrow_filled: LeftArrowFilled,
  left_arrow_grayed: LeftArrowGrayed,
  list: List,
  login_background: LoginBackground,
  model_3d: ModelD,
  order: Order,
  order_cart: OrderCart,
  order_cart_filled: OrderCartFilled,
  order_exclamatory: OrderExclamatory,
  order_time: OrderTime,
  picture: Picture,
  product_3d_model: ProductDModel,
  product_cart: ProductCart,
  product_moodboard: ProductMoodboard,
  product_verified: ProductVerified,
  project: Project,
  project_icon: ProjectIcon,
  Project_timer_icon: ProjectTimerIcon,
  right_arrow_filled: RightArrowFilled,
  right_arrow_grayed: RightArrowGrayed,
  rupee: Rupee,
  search: Search,
  sidebar_bg: SidebarBg,
  star_empty: StarEmpty,
  star_filled: StarFilled,
  ticked: Ticked,
  time: Time,
  unassigned_projects_icon: UnassignedProjectsIcon,
  vector: Vector,
  vendor: Vendor,
  view: View,
  Content: Content,
  Tag: Tag,
};
