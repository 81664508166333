import React from "react";
import Icon from "./Icon.js";

export const Account = (props) => <Icon {...props} name="account" />;
export const Apps = (props) => <Icon {...props} name="apps" />;
export const ArrowDown = (props) => <Icon {...props} name="arrow_down" />;
export const ArrowUp = (props) => <Icon {...props} name="arrow_up" />;
export const Bell = (props) => <Icon {...props} name="bell" />;
export const Cart = (props) => <Icon {...props} name="cart" />;
export const CartFilled = (props) => <Icon {...props} name="cart_filled" />;
export const Catalogue = (props) => <Icon {...props} name="catalogue" />;
export const CatalogueIcon = (props) => (
  <Icon {...props} name="catalogue_icon" />
);
export const CharmCircleTick = (props) => (
  <Icon {...props} name="charm_circle-tick" />
);
export const Chat = (props) => <Icon {...props} name="chat" />;
export const CircleTick = (props) => <Icon {...props} name="circle_tick" />;
export const Close = (props) => <Icon {...props} name="close" />;
export const CloseWhite = (props) => <Icon {...props} name="close_white" />;
export const Completed = (props) => <Icon {...props} name="completed" />;
export const Correct = (props) => <Icon {...props} name="correct" />;
export const Customer = (props) => <Icon {...props} name="customer" />;
export const DeleteWhite = (props) => <Icon {...props} name="delete_white" />;
export const Designer = (props) => <Icon {...props} name="designer" />;
export const Download = (props) => <Icon {...props} name="download" />;
export const DownloadArrow = (props) => (
  <Icon {...props} name="download_arrow" />
);
export const DownloadWhite = (props) => (
  <Icon {...props} name="download_white" />
);
export const Edit = (props) => <Icon {...props} name="edit" />;
export const Graph = (props) => <Icon {...props} name="graph" />;
export const GreenTick = (props) => <Icon {...props} name="green_tick" />;
export const Grid = (props) => <Icon {...props} name="grid" />;
export const Group = (props) => <Icon {...props} name="Group" />;
export const HeartFilled = (props) => <Icon {...props} name="heart_filled" />;
export const HeartGrayed = (props) => <Icon {...props} name="heart_grayed" />;
export const Hidden = (props) => <Icon {...props} name="hidden" />;
export const Home = (props) => <Icon {...props} name="home" />;
export const Hr = (props) => <Icon {...props} name="hr" />;
export const LeftArrowFilled = (props) => (
  <Icon {...props} name="left_arrow_filled" />
);
export const LeftArrowGrayed = (props) => (
  <Icon {...props} name="left_arrow_grayed" />
);
export const List = (props) => <Icon {...props} name="list" />;
export const LoginBackground = (props) => (
  <Icon {...props} name="login_background" />
);
export const ModelD = (props) => <Icon {...props} name="model_3d" />;
export const Order = (props) => <Icon {...props} name="order" />;
export const OrderCart = (props) => <Icon {...props} name="order_cart" />;
export const OrderCartFilled = (props) => (
  <Icon {...props} name="order_cart_filled" />
);
export const OrderExclamatory = (props) => (
  <Icon {...props} name="order_exclamatory" />
);
export const OrderTime = (props) => <Icon {...props} name="order_time" />;
export const Picture = (props) => <Icon {...props} name="picture" />;
export const ProductDModel = (props) => (
  <Icon {...props} name="product_3d_model" />
);
export const ProductCart = (props) => <Icon {...props} name="product_cart" />;
export const ProductMoodboard = (props) => (
  <Icon {...props} name="product_moodboard" />
);
export const ProductVerified = (props) => (
  <Icon {...props} name="product_verified" />
);
export const Project = (props) => <Icon {...props} name="project" />;
export const ProjectIcon = (props) => <Icon {...props} name="project_icon" />;
export const ProjectTimerIcon = (props) => (
  <Icon {...props} name="Project_timer_icon" />
);
export const RightArrowFilled = (props) => (
  <Icon {...props} name="right_arrow_filled" />
);
export const RightArrowGrayed = (props) => (
  <Icon {...props} name="right_arrow_grayed" />
);
export const Rupee = (props) => <Icon {...props} name="rupee" />;
export const Search = (props) => <Icon {...props} name="search" />;
export const SidebarBg = (props) => <Icon {...props} name="sidebar_bg" />;
export const StarEmpty = (props) => <Icon {...props} name="star_empty" />;
export const StarFilled = (props) => <Icon {...props} name="star_filled" />;
export const Ticked = (props) => <Icon {...props} name="ticked" />;
export const Time = (props) => <Icon {...props} name="time" />;
export const UnassignedProjectsIcon = (props) => (
  <Icon {...props} name="unassigned_projects_icon" />
);
export const Vector = (props) => <Icon {...props} name="vector" />;
export const Vendor = (props) => <Icon {...props} name="vendor" />;
export const View = (props) => <Icon {...props} name="view" />;
export const Content = (props) => <Icon {...props} name="Content" />;
export const Tag = (props) => <Icon {...props} name="Tag" />;
