import { lazy } from 'react'
import { ADMIN_LAYOUT } from '@constants/layouts'
import {
  CATALOGUE_DASHBOARD,
  CATALOGUE_PAYMENTS,
  CATALOGUE_PRODUCT_CATALOGUE,
  CATALOGUE_PRODUCT_DETAIL_VIEW,
  CATALOGUE_PRODUCT_DETAIL_EDIT,
  CATALOGUE_PRODUCT_VERIFICATION,
  CATALOGUE_PRODUCT_TAGGING,
  CATALOGUE_PRODUCT_3D_MODEL,
  CATALOGUE_VERIFICATION_PRODUCT_DETAIL_EDIT,
  CATALOGUE_VERIFICATION_PRODUCT_DETAIL_VIEW,
} from "../routeNames"

const catalogueRoutes = [
  {
    path: CATALOGUE_DASHBOARD,
    component: lazy(() => import('@src/pages/catalogue/dashboard/List')),
    layout: ADMIN_LAYOUT
  },
  {
    path: CATALOGUE_VERIFICATION_PRODUCT_DETAIL_EDIT,
    component: lazy(() => import('@src/pages/catalogue/product-verification/Edit')),
    layout: ADMIN_LAYOUT
  },
  {
    path: CATALOGUE_VERIFICATION_PRODUCT_DETAIL_VIEW,
    component: lazy(() => import('@src/pages/catalogue/product-verification/View')),
    layout: ADMIN_LAYOUT
  },
  {
    path: CATALOGUE_PRODUCT_DETAIL_EDIT,
    component: lazy(() => import('@src/pages/catalogue/product-catalogue/Edit')),
    layout: ADMIN_LAYOUT
  },
  {
    path: CATALOGUE_PRODUCT_DETAIL_VIEW,
    component: lazy(() => import('@src/pages/catalogue/product-catalogue/View')),
    layout: ADMIN_LAYOUT
  },
  {
    path: CATALOGUE_PRODUCT_CATALOGUE,
    component: lazy(() => import('@src/pages/catalogue/product-catalogue/List')),
    layout: ADMIN_LAYOUT
  },
  // {
  //   path: CATALOGUE_PRODUCT_DETAIL_VIEW,
  //   component: lazy(() => import('@src/pages/catalogue/product-verification/View')),
  //   layout: ADMIN_LAYOUT
  // },
  {
    path: CATALOGUE_PRODUCT_VERIFICATION,
    component: lazy(() => import('@src/pages/catalogue/product-verification/List')),
    layout: ADMIN_LAYOUT
  },
  {
    path: CATALOGUE_PRODUCT_TAGGING,
    component: lazy(() => import('@src/pages/catalogue/product-tagging/List')),
    layout: ADMIN_LAYOUT
  },
  {
    path: CATALOGUE_PRODUCT_3D_MODEL,
    component: lazy(() => import('@src/pages/catalogue/3d-model/List')),
    layout: ADMIN_LAYOUT
  },
  {
    path: CATALOGUE_PAYMENTS,
    component: lazy(() => import('@src/pages/catalogue/payments/List')),
    layout: ADMIN_LAYOUT
  }
]

export default catalogueRoutes
