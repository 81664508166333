import { lazy } from 'react'
import { ADMIN_LAYOUT } from '@constants/layouts'
import {
  CUSTOMER_DASHBOARD,
  CUSTOMER_REVIEW,
  QUERY_MANAGEMENT,
  PROJECT_DETAIL,
  QUERY_PROJECT_DETAIL
} from "../routeNames"

const customerRoutes = [
  {
    path: CUSTOMER_DASHBOARD,
    component: lazy(() => import('@src/pages/customer/dashboard/List')),
    layout: ADMIN_LAYOUT
  },
  {
    path: PROJECT_DETAIL,
    component: lazy(() => import('@src/pages/customer/review-management/ProductDetails')),
    layout: ADMIN_LAYOUT
  },
  {
    path: CUSTOMER_REVIEW,
    component: lazy(() => import('@src/pages/customer/review-management/List')),
    layout: ADMIN_LAYOUT
  },
  {
    path: QUERY_PROJECT_DETAIL,
    component: lazy(() => import('@src/pages/customer/query-management/Details')),
    layout: ADMIN_LAYOUT
  },
  {
    path: QUERY_MANAGEMENT,
    component: lazy(() => import('@src/pages/customer/query-management/List')),
    layout: ADMIN_LAYOUT
  },
]

export default customerRoutes
