import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { validateAuthToken } from "../redux/actions/auth/LoginAction"
import { AUTH_FAILED, AUTH_REQUESTED, AUTH_SUCCESS } from "../redux/statusTypes"

const initialAuthResponse = {
  isAuthenticated: false,
  currentUser: null,
  authenticating: true,
  isSessionValidated: false
}

export const useAuth = () => {

  const authStatus = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const [authState, setAuthState] = useState(() => {
    return {
      ...initialAuthResponse,
      isAuthenticated: authStatus.isAuthenticated,
      currentUser: authStatus.currentUser,
      authenticating: authStatus.status === AUTH_REQUESTED
    }
  })

  const isSessionValidated = authStatus.currentSessionValidated

  const authenticateToken = () => {
    dispatch(validateAuthToken)
  }

  useEffect(() => {
    // Don't call the api if the session is already validated
    if (!isSessionValidated) authenticateToken()
  }, [])

  useEffect(() => {
    const currentAuthStatus = authStatus.status

    if (currentAuthStatus === AUTH_SUCCESS) {
      setAuthState({
        isAuthenticated: true,
        authenticating: false,
        currentUser: authStatus.currentUser,
        isSessionValidated: authStatus.currentSessionValidated
      })
    } else if (currentAuthStatus === AUTH_FAILED) {
      setAuthState({
        isAuthenticated: false,
        authenticating: false,
        currentUser: null,
        isSessionValidated: authStatus.currentSessionValidated
      })
    } else if (currentAuthStatus === AUTH_REQUESTED) {
      setAuthState({
        isAuthenticated: false,
        authenticating: true,
        currentUser: null,
        isSessionValidated: authStatus.currentSessionValidated
      })
    }
  }, [authStatus.status])

  return [authState]
}
