import { lazy } from "react";
import { TAGS_DATABASE } from "../routeNames";
import { ADMIN_LAYOUT } from "@constants/layouts";

const tagsRoutes = [
  {
    path: TAGS_DATABASE,
    component: lazy(() => import("@src/pages/tags/tagsDatabase")),
    layout: ADMIN_LAYOUT,
    action: ["", ""],
  },
];

export default tagsRoutes;
