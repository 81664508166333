import { lazy } from 'react'
import AdminRoutes from './adminRouteConfig'
import AuthRoutes from './authRouteConfig'

/** @type {import('./appRouteConfig/index').RouteConfig} */
const DefaultRoute = {
  component: lazy(() => import('@src/pages/vendor/dashboard/List')),
  publicRoute: true,
  path: '/default'
}

const allRoutes = [
  ...[DefaultRoute],
  ...AuthRoutes,
  ...AdminRoutes
]

export default allRoutes
