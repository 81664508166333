import React from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import LOGIN_BACKGROUND from '@assets/img/brand/login_background.svg'

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className="main-content" ref={mainContent} >
        <div className="header" style={{
        backgroundImage: `url(${LOGIN_BACKGROUND})`
      }}>
          <Container>
            <div className="header-body w-100">
              <Row className="justify-content-center align-items-center h-100vh">
                {props.children}
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Auth;
