import axios from "axios"
import { AUTH_TOKEN } from "../constants/localKeys"
import { handleParameter } from "./handleParameter"

const requestHandler = request => {
  const authToken = localStorage.getItem(AUTH_TOKEN)
  request.headers.Authorization = authToken
  return request
}

const responseHandler = response => {
  if (response.status === 401) {
    window.localStorage.removeItem("@pm-admin/auth_token")
    window.location = '/login'
  }

  return response.data
}

const errorHandler = error => {
  return Promise.reject(error)
}

const mxdApiInstance = axios.create({
  headers: {
    'content-type': 'application/json'
  },
  timeout: 50000
})

mxdApiInstance.interceptors.request.use(
  (request) => (
    handleParameter(request.url, request.data, request),
    requestHandler(request)
  ),
  (error) => errorHandler(error)
)

mxdApiInstance.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => {
    let errorMessage = {
      message: 'Something went wrong'
    }
    if (error.response) {
      errorMessage = error.response.data
    }

    return errorHandler(errorMessage)
  }
)

export default mxdApiInstance
