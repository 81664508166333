import { lazy } from "react";
import {
  VENDOR_DASHBOARD,
  VENDOR_PAYMENTS,
  VENDOR_DATABASE,
  VENDOR_DETAILS,
  VENDOR_NOTIFICATION,
  EDIT_VIEW,
} from "../routeNames";
import { ADMIN_LAYOUT } from "@constants/layouts";

const vendorRoutes = [
  {
    path: VENDOR_DASHBOARD,
    component: lazy(() => import("@src/pages/vendor/dashboard/List")),
    layout: ADMIN_LAYOUT,
    action: ["viewVendor", "manageVendor"],
  },
  {
    path: VENDOR_DATABASE,
    component: lazy(() => import("@src/pages/vendor/database/List")),
    layout: ADMIN_LAYOUT,
    action: ["viewVendor", "manageVendor"],
  },
  {
    path: VENDOR_PAYMENTS,
    component: lazy(() => import("@src/pages/vendor/payments/List")),
    layout: ADMIN_LAYOUT,
    action: ["viewVendor", "manageVendor"],
  },
  {
    path: VENDOR_NOTIFICATION,
    component: lazy(() => import("@src/pages/vendor/notification/List")),
    action: ["viewVendor", "manageVendor"],
  },
  {
    path: EDIT_VIEW,
    component: lazy(() => import("@src/pages/vendor/database/Edit")),
    layout: ADMIN_LAYOUT,
    action: ["manageVendor"],
  },
  {
    path: VENDOR_DETAILS,
    component: lazy(() => import("@src/pages/vendor/database/View")),
    layout: ADMIN_LAYOUT,
    action: ["viewVendor", "manageVendor"],
  },
];

export default vendorRoutes;
